import React from 'react';
import { navigate } from 'gatsby';
import { ACCOUNT_ROUTE } from 'constants/navigation';

import useHasSubscription from 'hooks/use-has-subscription';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

import { FormHelperText, Hidden, Button } from '@material-ui/core';
import useStyles, { useButtonClasses } from './styles';

const PissTakeBanner = () => {
  const { pissTake, hasSub } = useHasSubscription();
  const styles = useStyles();
  const buttonClasses = useButtonClasses();

  return (
    <>
      {pissTake && (
        <>
        <Hidden smUp>
          <Button
            classes={buttonClasses}
            variant="outlined"
            aria-label="subscription limit reached"
            className={styles.messageButton}
            onClick={() => navigate(ACCOUNT_ROUTE)}
            color="primary"
            fullWidth
            >
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className={styles.icon}/>
             {hasSub ? 'Subscription exceeded' : 'Free Tier used'}
            <FormHelperText className={styles.helpText}>
              {'Functionality will be restricted'}
            </FormHelperText>
            <FormHelperText className={styles.helpText}>
              {hasSub ? 'Please update your subscription' : 'Please purchase a subscription'}
            </FormHelperText>
        </Button>
        </Hidden>
        <Hidden xsDown>
          <Button
            classes={buttonClasses}
            variant="outlined"
            aria-label="exceeded subscription limit"
            className={styles.messageButton}
            onClick={() => navigate(ACCOUNT_ROUTE)}
            color="primary"
            fullWidth
            >
            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className={styles.icon}/>
            {hasSub
              ? 'You have reached your subscription limits'
              : 'You have reached the limits of a free account'}
            <FormHelperText className={styles.helpText}>
            {hasSub
              ? 'Some Functionality has been restricted please update your subscription'
              : 'Some Functionality has been restricted please purchase a subscription'}
            </FormHelperText>
          </Button>
        </Hidden>
        </>
      )}
    </>
  );
};

export default PissTakeBanner;
