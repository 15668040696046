import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import { orange } from '@material-ui/core/colors';

export default makeStyles({
  messageButton: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: orange[800],
  },
  helpText: {
    marginTop: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.fontSize,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
});

export const useButtonClasses = makeStyles({
  root: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderWidth: theme.spacing(0.25),
    borderColor: orange[800],
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },

    '&:hover': {
      borderWidth: theme.spacing(0.35),
      borderColor: orange[900],
    },
  },
});
